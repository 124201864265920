import { useState } from "react";

// const shouldResetIntro = (date) => {
//   const today = new Date();
//   const day = new Date(date);
//   const diffSeconds = Math.floor((day-today)/(1*1*1000));
//   if(diffSeconds > 0){
//     return true;
//   }
//   return false;
// }

function useShowIntro() {
  // const storageKey = 'showIntro';
  const [ si, setSi ] = useState({
    should: true,
    date: null,
  });

  const setShow = (value) => {
    setSi({
      should: value,
      date: new Date(),
    });
  };

  // useEffect(() => {
  //   const localFn = async () => {
  //     const showIntroRes = await asyncLocalStorage.getItem(storageKey);
  //     try{
  //       const showIntro = JSON.parse(showIntroRes);
  //       if(showIntro === null){
  //         setShow(true);
  //         return;
  //       }
  //       if(shouldResetIntro(showIntro.date)){
  //         setShow(true);
  //         return;
  //       }
  //       setSi({
  //         should: showIntro.should,
  //         date: new Date(showIntro.date),
  //       });
  //     } catch(e) {
  //       console.error(e);
  //     }
  //   }
  //   localFn();
  // }, []);

  // useEffect(() => {
  //   const localFn = async () => {
  //     await asyncLocalStorage.setItem(storageKey, JSON.stringify(si));
  //   };
  //   localFn();
  // }, [si]);

  return [si.should, setShow];
}

export default useShowIntro;